import React from "react";
import { ButtonLink } from "../ButtonElements";
import {
  Column1,
  InfoContainer,
  InfoRow,
  InfoWrapper,
  Subtitle,
  TextWrapper,
  BtnWrap,
  TopLine,
  TarifSectionContainer,
  TarifWrapBorder,
  TarifInfoWrap,
  TarifImage,
  TarifText,
  TarifBorderBlock,
} from "../SixthService/SixthServiceElements";

import TimeImage from "../../images/hourglass.png";
import EuroImage from "../../images/euro.png";

const SixthService = () => {
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <InfoRow style={{ margin: "70px 0 0 0" }}>
            <Column1>
              <TextWrapper style={{ maxWidth: "70vw" }}>
                <TopLine>Le massage Suéduois</TopLine>
                <Subtitle>
                 Le massage Suéduois est un massage Californien addressé aux sportifs. Il est tonique et plus dynamique. Enchaînant des manœuvres sur les muscles et articulations, il permet de ramener le sang vers le cœur.
                </Subtitle>
              </TextWrapper>
            </Column1>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>

      <TarifSectionContainer>
        <TarifWrapBorder>
          <TarifInfoWrap>
            <TarifBorderBlock>
              <TarifImage src={TimeImage} />
              <TarifText>1h</TarifText>
              <TarifText>Par séance</TarifText>
            </TarifBorderBlock>
          </TarifInfoWrap>
          <TarifInfoWrap>
            <TarifBorderBlock>
              <TarifImage src={EuroImage} />
              <TarifText>60.00€ TTC</TarifText>
              <TarifText>Par séance</TarifText>
            </TarifBorderBlock>
          </TarifInfoWrap>
        </TarifWrapBorder>
      </TarifSectionContainer>
      <InfoContainer>
        <InfoWrapper>
          <InfoRow style={{ margin: "0 0 50px 0" }}>
            <Column1>
              <TextWrapper style={{ maxWidth: "70vw" }}>
                <BtnWrap>
                  <ButtonLink
                    to="/contact"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    Prendre rendez vous
                  </ButtonLink>
                </BtnWrap>
              </TextWrapper>
            </Column1>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default SixthService;
